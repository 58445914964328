import React from "react";
import { motion } from "framer-motion";

function SvgComponent(props) {
  const imgVariants = {
    float: {
      scale: 1,
      y: 10,

      transition: {
        repeat: Infinity,
        repeatType: "reverse",
        duration: 1.8,
      },
    },
  };
  return (
    <motion.svg
      variants={imgVariants}
      animate="float"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 800.8 953.35"
      {...props}
    >
      <defs>
        <pattern
          id="prefix__New_Pattern"
          data-name="New Pattern"
          width={12}
          height={12}
          patternTransform="matrix(-1 0 0 1 5.96 -26.97)"
          patternUnits="userSpaceOnUse"
          viewBox="0 0 12 12"
        >
          <path className="prefix__cls-1" d="M0 0h12v12H0z" />
          <path className="prefix__cls-1" d="M0 0h12v12H0z" />
          <circle className="prefix__cls-2" cx={12} cy={3} r={2} />
          <circle className="prefix__cls-2" cx={6} r={2} />
          <circle className="prefix__cls-2" cy={3} r={2} />
          <circle className="prefix__cls-2" cx={12} cy={9} r={2} />
          <circle className="prefix__cls-2" cx={6} cy={6} r={2} />
          <circle className="prefix__cls-2" cy={9} r={2} />
          <circle className="prefix__cls-2" cx={6} cy={12} r={2} />
        </pattern>
        <style>
          {
            ".prefix__cls-1,.prefix__cls-11,.prefix__cls-5,.prefix__cls-6,.prefix__cls-8,.prefix__cls-9{fill:none}.prefix__cls-2{fill:#949494}.prefix__cls-10,.prefix__cls-11,.prefix__cls-4,.prefix__cls-5,.prefix__cls-6{fill-rule:evenodd}.prefix__cls-4{fill:url(#prefix__New_Pattern)}.prefix__cls-5,.prefix__cls-6,.prefix__cls-8,.prefix__cls-9{stroke:#000;stroke-width:2.5px}.prefix__cls-11,.prefix__cls-5,.prefix__cls-6,.prefix__cls-8,.prefix__cls-9{stroke-linecap:round}.prefix__cls-5,.prefix__cls-9{stroke-miterlimit:10}.prefix__cls-11,.prefix__cls-6,.prefix__cls-8{stroke-linejoin:round}.prefix__cls-11{stroke:#bcbcbc;stroke-width:4px}"
          }
        </style>
      </defs>
      <title>{"schoolbooks-monochrome"}</title>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <motion.path
            d="M753.19 198.55c-41.58-63.81-113.48-110-190.38-131.68l-10.14 39a8.28 8.28 0 013.87-3.72c4.92-2.11 8.28-.59 9.83 1.35 2.22-3.49 4.68-5.7 8.09-6.9 8.12-2.86 13.63 4.65 15.85 11.33 3.57 10.74 1.21 20.54-2.22 22a9.22 9.22 0 01-11.09-2.8c-.24 2.93-.66 5.37-6 7.69-8 3.47-16.84-7-18.93-12.4a22.09 22.09 0 01-1.37-8.85l-8 30.93L529 141l20.19-77.65c-36.89-9-74.58-12.25-110.33-9.12a70.42 70.42 0 017.8 23.29c5 29.64-8.85 55.93-30.95 58.72-7.31.92 2.69.27 3.93.27l-.88 22.3c5.33-1.68 23.1 4.37 28.8 9.84 39.48-7.45 158.9-40.17 158.9-40.17l6 23.78c-3.38.86-10.54 3-10.54 3s11 40 10.06 39.79l41.57-9.93 6 23.77-65 17.4-12 3.19 8.6 34.19-96.86 24.21.17.4c49.42-12.14 105.48-26 112.19-28.2 2.24 12.06 7.67 23.42-20.82 34.12-14.45 5.43-14.78-1.67-34.69 2.82-5.48 1.24-17.88 7.84-33.66 16.59 5.37 27.64 6.85 50 5 57.44C516.37 395 383.05 418.72 357 408.89l.67 20.25-39.75 7.92a16.28 16.28 0 01-19.16-12.79l-44-220.87a16.28 16.28 0 0112.8-19.16l60.33-12A16.29 16.29 0 01347 185l3 15.14c7.55-15.17 18.22-27.39 32.93-34.69-1.12-15.19-2.71-30.35-5.6-43.12a34.39 34.39 0 01-6.31 1.92c-14.21 2.68-23.24-.39-37.19 2C304 131.44 303.2 165 255.6 172a94.88 94.88 0 01-18.75.87C190.74 229.05 153.43 297 118.32 357.71 52.86 470.92-5.43 559 .4 652.06 6 745.32 75.5 843.29 175.71 900c100 56.72 230.68 72.19 336.49 25.33q2.29-1 4.56-2.06l-1.15-7.48 29.33-7.35c90.07-53.38 159.77-154 206.69-280.14 52.68-141.23 76.66-314.3 1.56-429.75zm-186.53-100c-3.91-3.28-8.74-24.55 2.63-23.55 11.71 1 2.91 19.6-2.63 23.56zm-297.54 799.8L212 792.56l32.52-16.63 22.77 35.89 18 78z"
            fill="#F0ED46"
          />
          <path
            className="prefix__cls-4"
            d="M353.72 405.31c-8.52-11.9-36.59-137.2-3.69-205.15L347 185a16.29 16.29 0 00-19.16-12.8l-60.33 12a16.28 16.28 0 00-12.8 19.16l44 220.87a16.28 16.28 0 0019.16 12.79l39.75-7.92-.71-21.59a7.48 7.48 0 01-3.19-2.2z"
          />
          <path
            className="prefix__cls-5"
            d="M518.06 312.44c5.36 27.63 6.85 50 5 57.43-6.54 25.7-158.18 51-169.3 35.44-10.48-14.64-50.58-201 29.77-240.91M468.47 188.63a158.62 158.62 0 0112.12 17.67M419.31 157.67a61.31 61.31 0 0128.28 10.94"
          />
          <path
            className="prefix__cls-6"
            d="M421.06 172.05c-2.48-6.56-2-27.46-.86-36.69M376.1 114.25c4.58 15.85 6.55 36.86 7.88 57.24 4 3.49 11.72 4.78 19.11 4.71"
          />
          <path d="M524.92 415.33z" fill="#ffe4ad" fillRule="evenodd" />
          <path
            className="prefix__cls-6"
            d="M529 141l30.44-117L573 27.54l-30.41 117z"
          />
          <path
            className="prefix__cls-8"
            d="M537.29 129.94l7.8 2M541.29 114.56l7.79 2M545.29 99.18l7.79 2M549.29 83.8l7.79 2M553.29 68.42l7.79 2M557.29 53l7.79 2M561.29 37.66l7.79 2"
          />
          <path
            className="prefix__cls-6"
            d="M574.46 96.61c8.12-2.86 13.63 4.65 15.85 11.33 3.57 10.74 1.21 20.54-2.22 22a9.22 9.22 0 01-11.09-2.81c-.24 2.93-.66 5.37-6 7.69-8 3.47-16.84-7-18.93-12.4-2.78-7.1-1.47-17.7 4.5-20.26 4.92-2.11 8.28-.59 9.83 1.35 2.19-3.51 4.65-5.7 8.06-6.9z"
          />
          <path
            className="prefix__cls-4"
            d="M569.29 75c11.75 1 2.91 19.59-2.63 23.55-3.91-3.28-8.74-24.55 2.63-23.55z"
          />
          <path
            className="prefix__cls-6"
            d="M356.74 306c-.71 21.41 1.17 54.92 30.47 61.7 33.73 7.81 144.6-67.35 164.51-71.83S572 298.45 586.4 293c28.5-10.7 23.07-22.07 20.82-34.12-12.05 3.92-183.61 45.63-189.4 47.15a37.88 37.88 0 01-1.74-4.43"
          />
          <path
            className="prefix__cls-5"
            d="M365 201.59a25.15 25.15 0 0113.77-4.84 30.19 30.19 0 0115.9 3.46M421.61 245.5c5.7 24.71 4.78 50.19 1.31 53.36-7 6.44-73.43 11.12-78.4 3.81-4.36-6.4-7.14-81.12 20.46-101.08"
          />
          <path
            className="prefix__cls-6"
            d="M604.08 222.87l-123.18 31c-11.29 2.84-18.52 12.8-16.15 22.24s13.46 14.79 24.76 11.94l123.18-31M591.73 262.5l-8.6-34.19"
          />
          <path
            className="prefix__cls-4"
            d="M479.19 229.13l174.4-44.08 6 23.77-174.39 44.09z"
          />
          <path
            className="prefix__cls-6"
            d="M480.59 206.3l-4.86-19.2L602 155.19 612 195l-126.21 31.89-5.2-20.59M432.09 172.53l174.4-44.09 6 23.78-174.38 44.08z"
          />
          <path className="prefix__cls-9" d="M483.71 257.66l6.24 25.2" />
          <path
            className="prefix__cls-4"
            d="M488.25 200.66l35.63-9 2.84 11.23-35.63 9z"
          />
          <path className="prefix__cls-9" d="M444.63 183.83l71.88-18.41" />
          <path
            className="prefix__cls-5"
            d="M212 792.56l57.13 105.79 16.11-8.5-18-78-22.77-35.89zM521.17 951.89l118.38-21-3-18-79.71-7.49-41.23 10.33z"
          />
          <path
            className="prefix__cls-6"
            d="M439.56 52.6c3.23-8.18 5.1-11.81 4.06-19.79C439.1 8.28 415-2.31 393.63 2.3c-37.08 8-55 28-77.14 31.73-33.6 5.62-64.9-7-100.17 2.3-47.79 12.6-54.9 46.73-47.94 77.35 11.51 50.65 55.07 62 87.75 57.22 47.6-7 48.41-40.56 78.26-45.69 13.95-2.39 23 .67 37.19-2a34.79 34.79 0 006.32-1.93"
          />
          <path
            className="prefix__cls-6"
            d="M433.43 44.74c7.58 8.43 11.71 19.34 13.78 31.64 5 29.64-8.85 55.93-30.94 58.72a33.92 33.92 0 01-21.27-4.7M389.59 84.33c-5.67 3.12-11.19 5.81-16 7.88M432.71 41.56c.76 4-1.06 8.54-4.57 13.22M379.21 111.18a10.62 10.62 0 11-6.57-19 10.85 10.85 0 013.19.48"
          />
          <path
            className="prefix__cls-10"
            d="M517.07 377.45c-21.89 17-96.83 32-137.4 32.6 19.53 63.49 135.94 505.74 135.94 505.74l37.62-9.43z"
          />
          <path
            className="prefix__cls-10"
            d="M353.72 405.31a14.7 14.7 0 01-1.06-1.85c7.57 39.41 40.51 209.32 50.89 234.87-15.6 12.66-159 137.6-159 137.6l18.45 29.2 205-110.79-34.85-290c-37.79 6.55-74.15 8.28-79.43.97z"
          />
          <path
            className="prefix__cls-6"
            d="M357.63 429.14l-39.75 7.92a16.28 16.28 0 01-19.16-12.79l-44-220.87a16.28 16.28 0 0112.8-19.16l60.33-12A16.29 16.29 0 01347 185l3 15.14"
          />
          <path
            className="prefix__cls-11"
            d="M343.82 191a42 42 0 0117.72-8.72M361.27 339.2c-9.95-6.7-19-16.78-26-29.27M361.54 182.32c31.34-6.88 64.86 24.5 74.88 70.1 4 18.07 3.65 35.65-.14 50.73"
          />
        </g>
      </g>
    </motion.svg>
  );
}

export default SvgComponent;
