import React from "react";

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 661.21 824.17"
      {...props}
    >
      <defs>
        <style>
          {
            ".prefix__cls-1{fill:#f2f2f2}.prefix__cls-2{fill:#a4a4a4}.prefix__cls-3{fill:none;strokeLinecap:round;strokeLinejoin:round;stroke:#000;stroke-width:2.5px}.prefix__cls-5{fill:#e0e0e0}"
          }
        </style>
      </defs>
      <title>{"directions-monochrome"}</title>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__vector">
          <path
            className="prefix__cls-1"
            d="M573.26 219.13c-32.12-60.08-139.48-80.24-233.62-69.81-87.75 9.57-164.17 45.79-225.8 105.13a49.12 49.12 0 015 21.69 50 50 0 01-.44 6.52 58 58 0 1135.32 110.18v16.76a83 83 0 0137.06 23.47c25.28 8 60.38 49 62.34 63.11-2.78 4.61-14.15 13.79-26.49 21.48 9.51 56.58 2.69 116.94-1.36 134.74-31.82 17.88-132 15.88-165-2.27a334.32 334.32 0 00117.21 93c91.92 43.53 204.42 42.28 264.77-9.18 60.36-51.31 68.56-152.69 94.29-253.51s68.7-201.1 36.72-261.31zm-190 304.94c-7.84 0-14.21-10.51-14.21-23.47s6.37-23.48 14.21-23.48 14.21 10.51 14.21 23.48-6.37 23.47-14.22 23.47z"
          />
          <path
            className="prefix__cls-1"
            d="M24.66 516.36a17.87 17.87 0 01-4.4-3.48c-2.53-19 25.83-88.4 51.25-84A15.14 15.14 0 0180 433.7c8.41-13.37 19.09-22.9 32.52-26.54v-19.6a58 58 0 01-29-64.14 49.24 49.24 0 01-27.54.11c-39.4 59-63.25 128.77-54 196.94a238 238 0 0016.54 59.87c.48-25.26 3.89-52.25 6.14-63.98zM48.05 633.73c3 4.36 6.18 8.65 9.46 12.87-.81-4.5-1.57-9.64-2.24-15.25-2.5.94-4.91 1.75-7.22 2.38z"
          />
          <path
            className="prefix__cls-2"
            d="M633.23 41.13L439.01 82.8V42.13L632.43.64l28.78 14.98-27.98 25.51zM439.01 42.13L242.63 0l-25.81 14.85 23.56 25.34L439.01 82.8V42.13z"
          />
          <path d="M59.36 644.53c-1 13.85-7 76.42-2.9 95.71h178l-18.31-92.49z" />
          <path
            className="prefix__cls-3"
            d="M190.8 433.07c25.28 8 60.38 49 62.34 63.11"
          />
          <path
            stroke="#a4a4a4"
            strokeWidth={11}
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M439.01 18.3v800.37"
          />
          <path
            className="prefix__cls-3"
            d="M153.74 409.59C250 439 232.82 619.29 225.29 652.4c-32.62 18.33-137.15 15.77-167.39-3.67-1-5-1.85-10.86-2.64-17.38M80 433.7c8.41-13.38 19.1-22.9 32.53-26.54"
          />
          <path
            className="prefix__cls-3"
            d="M112.64 560.28c-14 4.1-39.9 13.19-44.84 16.26 4.4-10.08 12.14-35.95 13.87-48"
          />
          <path
            className="prefix__cls-3"
            d="M24.65 516.36c-4.37 22.84-13.22 103.46 4.13 116.55 18.72 14.13 87.29-36.4 101.13-48"
          />
          <path
            className="prefix__cls-5"
            d="M439.01 83.45l-194.49 41.72-27.7-14.59 20.03-24.43 202.16-43.37v40.67zM635.91 85.02l-196.9-42.24v40.67l194.5 41.72 27.7-15.35-25.3-24.8z"
          />
          <path
            className="prefix__cls-3"
            d="M112 251a49.45 49.45 0 016.48 31.72M83.52 323.43A49.3 49.3 0 1165.85 227"
          />
          <path
            className="prefix__cls-3"
            d="M107.24 384.34A58 58 0 11140 394.49c-1 0-1.92 0-2.87-.07M100.93 379.33C130 356.43 141.3 323 145.3 297.6"
          />
          <path
            className="prefix__cls-3"
            d="M145.3 297.6a68.73 68.73 0 0028.19 6 69.46 69.46 0 0013.38-1.3M136.5 430c-11.3.44-24-1.44-24-5.54v-36.9M153.74 392.84v31.59M91.89 504.63c-1.15 10.73-3.32 19.45-6.13 23.36-10.15 2.81-56.27-4.32-65.5-15.11-2.53-19 25.83-88.4 51.25-84 7.63 1.31 12.91 9 16.3 19.54"
          />
          <path
            className="prefix__cls-5"
            d="M383.25 524.07c-7.84 0-14.21-10.51-14.21-23.47s6.37-23.48 14.21-23.48a9.41 9.41 0 014.79 1.37V420l-256.47 70v39.32a20.5 20.5 0 015.21-.67c13.39 0 24.26 12.85 24.26 28.71S150.17 586 136.78 586a20.5 20.5 0 01-5.21-.66v99.26l256.47-70v-91.9a9.31 9.31 0 01-4.79 1.37z"
          />
          <ellipse
            className="prefix__cls-3"
            cx={383.25}
            cy={500.6}
            rx={14.21}
            ry={23.47}
          />
          <path
            className="prefix__cls-3"
            d="M112.64 560.28c-.08-1-.13-2-.13-3 0-15.86 10.87-28.71 24.27-28.71S161 541.46 161 557.32 150.17 586 136.78 586a20.77 20.77 0 01-6.87-1.16"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
