import { motion } from "framer-motion";
import React from "react";

function SvgComponent(props) {
  const imgVariants = {
    float: {
      scale: 1,
      y: 20,
      transition: {
        repeat: Infinity,
        repeatType: "reverse",
        duration: 1.8,
      },
    },
  };
  return (
    <svg
      id="prefix__Vector"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1200 1200"
      {...props}
    >
      <defs>
        <style>
          {
            ".prefix__cls-1{fill:#dfdfdf}.prefix__cls-2{fill:#f3f3f3}.prefix__cls-3,.prefix__cls-4,.prefix__cls-5,.prefix__cls-7,.prefix__cls-8{fill:none}.prefix__cls-3{stroke:#dfdfdf;stroke-miterlimit:10}.prefix__cls-3,.prefix__cls-4,.prefix__cls-7{stroke-width:4px}.prefix__cls-4,.prefix__cls-5{stroke:#000}.prefix__cls-4,.prefix__cls-5,.prefix__cls-7,.prefix__cls-8{stroke-linecap:round;stroke-linejoin:round}.prefix__cls-5{stroke-width:2.5px}.prefix__cls-7,.prefix__cls-8{stroke:#f3f3f3}.prefix__cls-8{stroke-width:2px}"
          }
        </style>
      </defs>
      <title>{"Artboard 1"}</title>
      <path
        className="prefix__cls-1"
        d="M262 808V453.49h-61v273.4c25.44 16.67 46.06 49.02 61 81.11zM201 927.82v31h18.18a167.33 167.33 0 01-18.18-31z"
      />
      <path
        className="prefix__cls-2"
        d="M261.28 453.49v353c14.15 30 23.34 60.07 26.9 77 5.85 27.85 25.55 46 25.55 74.14v1.21h210V453.49z"
      />
      <path
        className="prefix__cls-3"
        d="M205.45 483.05H176V716.4M655.57 316.71v329.85h9.56"
      />
      <path
        className="prefix__cls-4"
        d="M967.87 1008.54l-62.53-105.22M996.05 1008.54l-62.3-104.84M793.51 1000.31l57.5-96.76"
      />
      <path
        className="prefix__cls-5"
        d="M945.2 668.8h88.88l-64.53 233.83H805.87v-8.33h121.39l2.6-54.49"
      />
      <path d="M748.58 822.26c-13.45 0-80.58 156.29-80.58 162s14.57 12.19 16.72 12.19c3.35-3.11 67.61-96 74.3-102.48s-10.44-71.71-10.44-71.71z" />
      <path
        className="prefix__cls-5"
        d="M786.21 744.43a22.38 22.38 0 00-14.48-4.94c-10.69 0-19.36 6.57-19.36 14.67M847.4 713.29c-6.77 6.19-15.68 16.72-19.9 29"
      />
      <path
        className="prefix__cls-5"
        d="M883.72 678.4a19.67 19.67 0 00-7.34-1.64c-13.72 0-36.93 25-36.93 28.14 0 2.74 12.52 13.53 24.22 16.94M860.46 746.4c2.53-36.83 13.83-71.91 44.76-81.27M974.42 715.9c13.86 52.2 4.42 124.69-1.86 129.39-9.87 7.41-96 8.08-101.61.68-2.36-3.12-9.9-36.14-11-73.32M936.23 664.73c11.92 3.77 20.93 12.56 27.65 24.34M920.73 675.68c7.75 0 15.5-1.64 15.5-5v-33.29M905.22 653.12v12M917.78 652.86a21.74 21.74 0 01-10.09.82c-16.71-2.67-27-25-23.08-49.82.16-1 .34-2 .54-3M942.8 623.29a58.66 58.66 0 01-6.57 14.1"
      />
      <path
        d="M949.82 562.09c4.43-7.67 15.67-20.59 37.68-20.59 15 0 30 8 49 8 15 0 29-6 47-6 32 0 42 21 42 34 0 33-27 46-47 46-32 0-36-22-74-22-37 0-32-30-40-30a19.26 19.26 0 00-5.64.63"
        strokeWidth={2.5}
        stroke="#000"
        strokeMiterlimit={10}
        fill="none"
      />
      <path
        className="prefix__cls-5"
        d="M940.5 632.5c16.3 0 24-16.79 24-38 0-35-30.9-42-44-42-35.37 0-42.77 26.91-42.77 34.68s4.91 13.53 7.42 13.72c1.56-9.29 9-22.11 9.35-22.4a37.58 37.58 0 0011.42 7.63"
      />
      <path
        className="prefix__cls-5"
        d="M938.87 606.79a8.55 8.55 0 013.58-.77 8.64 8.64 0 010 17.28 8.75 8.75 0 01-2.62-.4M860 772.65c10.19 4.15 19.86 7.37 31.9 7.37 9.38 0 27.34-5.85 40.82-20.36M905.34 732a64.52 64.52 0 00-17.71 19.83c-8.25-1.54-41.81-9.93-71.6-9.93-2.8 0-10.07-2.3-13-2.3-17.47 0-21.12 9.44-21.12 12.23s.6 2.87 1.14 2.87c38.57 0 58.59 10.47 76.94 17.94"
      />
      <path
        className="prefix__cls-5"
        d="M947.81 690.54c9.88-2.81 19-2.77 24.67 2.73 18.74 18.19-28.65 68.54-36.25 67.8s-35-29.59-35.26-38.36c-.1-3 6.18-9.52 15.2-16"
      />
      <path d="M587.77 756.95H1011v13.66H587.77z" />
      <path
        className="prefix__cls-4"
        d="M988.26 974.33V766.79M636.61 974.33V766.79M958.54 1015.92V766.79M605.21 1015.92V766.79M815.07 1012.88l64.98-109.33"
      />
      <path d="M743.08 754.44h70.27v2.51h-70.27z" />
      <path
        stroke="#9d32ef"
        strokeWidth="2"
        fill="none"
        d="M714.43 702.26h-49.3V590.85h153.42v111.41h-67.48"
      />
      <path
        stroke="#9d32ef"
        strokeWidth="2"
        fill="none"
        d="M720.29 681.31l-21.16 75.64h37.09l20.54-75.64h-36.47z"
      />
      <motion.path
        variants={imgVariants}
        animate="float"
        fill="#F0ED46"
        d="M750.19 322.09a44.73 44.73 0 00-47.45-73.68 62 62 0 00-109 9.67 44.73 44.73 0 00-55.41 64z"
      />
      <path className="prefix__cls-3" d="M639.95 313.62v71.72H419.88v68.15" />
      <path
        className="prefix__cls-5"
        d="M296.48 1015.92c5.85 0 17.25-19.82 17.25-58.26 0-28.11-19.7-46.29-25.55-74.14-9-42.71-53.57-168.17-123.16-168.17-8 0-18.74 16.81-18.74 51.44 0 42 40.68 85.49 40.68 94.51.04 102.99 90.87 154.62 109.52 154.62z"
      />
      <path
        className="prefix__cls-5"
        d="M296.93 1015.92c0-5.63-8.14-43.4-12.37-52.84s-20.84-56.65-23.44-69.68-50.79-140.32-96.05-172.56M276.16 940.85l-75.92-15.46M279.98 951.4l-69.53-5.79M258.1 883.56l-70.57-21.36M264.82 906.95l21.54-15.66M224.04 804.06l21.28-24.13M217.39 791.21l18.11-24.57"
      />
      <path className="prefix__cls-1" d="M283.02 485.46h218.93v42.2H283.02z" />
      <circle className="prefix__cls-2" cx={486.23} cy={501.26} r={7.4} />
      <path className="prefix__cls-7" d="M358.62 501.26H472.6" />
      <path className="prefix__cls-1" d="M283.02 556.8h218.93V599H283.02z" />
      <circle className="prefix__cls-2" cx={486.23} cy={572.6} r={7.4} />
      <path className="prefix__cls-7" d="M358.62 572.6H472.6" />
      <path className="prefix__cls-1" d="M283.02 628.13h218.93v42.2H283.02z" />
      <circle className="prefix__cls-2" cx={486.23} cy={643.93} r={7.4} />
      <path className="prefix__cls-7" d="M358.62 643.93H472.6" />
      <path className="prefix__cls-1" d="M283.02 699.47h218.93v42.2H283.02z" />
      <circle className="prefix__cls-2" cx={486.23} cy={715.27} r={7.4} />
      <path className="prefix__cls-7" d="M358.62 715.27H472.6" />
      <path className="prefix__cls-1" d="M283.02 770.8h218.93V813H283.02z" />
      <circle className="prefix__cls-2" cx={486.23} cy={786.61} r={7.4} />
      <path className="prefix__cls-7" d="M358.62 786.61H472.6" />
      <path
        className="prefix__cls-8"
        d="M215.06 515.82h32.84M215.06 524.32h32.84M215.06 573.65h32.84M215.06 582.15h32.84M215.06 643.93h32.84M215.06 652.44h32.84M215.06 711.93h32.84M215.06 720.44h32.84"
      />
      <path
        className="prefix__cls-5"
        d="M891.88 574.25a12 12 0 002.62 4.25M794.73 1006.71l4.44 24.27-51.96 10.09 35.55-22.91-1.7-9.62M684.89 993.72l-12.56 21.07-46.38-27.62 42.26 6.98 5.02-8.31"
      />
      <path d="M972.43 854c0-4.3.75-9.28-1.93-10.39-63.62-26.23-156.76-29.63-171.11-29.63s-56.31 1.46-56.31 13.47c0 43.38 24.17 176.93 29.08 181.92 2.33 2.35 22.65.78 25.18-1.76-.17-11 6.22-121.49.39-134.87 53.47 19.6 114.19 28.88 130.19 28.88 46.37-.05 44.51-42.52 44.51-47.62zM187 861.3c0-5.25-13.79-22.19-25.31-43.72-21.16-15.92-40.12-25.58-50.35-25.58-6 0-8.69 10.78-8.69 16.49 0 59.78 64.09 129.27 120.1 155.1C202.87 938.5 187 904.4 187 861.3z" />
    </svg>
  );
}

export default SvgComponent;
